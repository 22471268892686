import React from 'react';
import ErrorPage from 'next/error';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Page from '../components/Page';
import { initializeApollo } from '../lib/apolloClient';
import { GET_LIVE_DATA } from '../utils/queries/live';

export default function Live({ liveFromServer, errorFromServer }) {
  return (
    <Page
      contentFromServer={liveFromServer}
      errorFromServer={errorFromServer}
    />
  );
}

export async function getStaticProps(ctx) {
  const liveId = 'ckymtzv7a415972hvi23fnp3hz';

  if (!liveId) {
    return <ErrorPage statusCode={404} />;
  }

  const apolloClient = initializeApollo();
  try {
    const { data } = await apolloClient.query({
      query: GET_LIVE_DATA,
      variables: { liveId }
    });

    return {
      props: {
        liveFromServer: data.live,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  } catch (error) {
    return {
      props: {
        errorFromServer: error?.graphQLErrors[0].originalError.extensions,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  }
}

// export async function getStaticPaths() {
//   const paths = [
//     // { params: { liveId: 'ckm66p54f39838kcv77g0wg8rw' } },
//   ];
//   return { paths, fallback: true };
// }
